import React, { useState } from 'react';
import './Avg.css';
import '../Cate.css';
import { useProduct } from '../../../context/ProductContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLocalContext } from '../../../context/LocalContext';

const Avg = () => {
  const { cart, setCart, setProductToShow } = useProduct();
  const navigate = useNavigate();
  const { webinfo } = useLocalContext();

  const [product] = useState([
    {
      id: '002',
      name: 'AVG Internet Security',
      desc: 'Advanced protection for safe browsing, shopping, and banking online. Includes firewall, anti-phishing, and ransomware protection for your PC.',
      price: '49.99',
      img: 'https://images-cdn.ubuy.co.in/650a859b8ed1a40eac4cbf33-avg-internet-security-2-year.jpg',
    },
    {
      id: '003',
      name: 'AVG Ultimate',
      desc: 'All-in-one protection with antivirus, VPN, and tune-up tools. Ideal for users looking for complete protection and performance optimization.',
      price: '89.99',
      img: 'https://5.imimg.com/data5/ECOM/Default/2023/4/299131465/PL/TB/XD/187171725/avg-ult16n24en-avg-ultimate-2016-1188689-61cabb15-14a9-4b94-80a3-a47a6c14d6fa-500x500.jpg',
    },
    {
      id: '004',
      name: 'AVG Secure VPN',
      desc: 'Protect your online privacy with AVG Secure VPN. Encrypts your connection for safe and anonymous browsing on any Wi-Fi network.',
      price: '59.99',
      img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtd7oJYOphRrgeTlKobAWFvSd7x-u7bwSABg&s',
    },
    {
      id: '006',
      name: 'AVG AntiTrack',
      desc: 'Protect your online privacy with AVG AntiTrack. Prevent trackers from collecting your personal information and browsing habits.',
      price: '49.99',
      img: 'https://5.imimg.com/data5/SELLER/Default/2023/2/YS/DP/KO/162327682/avg-antitrack-software.jpg',
    },
    {
      id: '007',
      name: 'AVG Driver Updater',
      desc: 'Keep your drivers up-to-date and ensure your PC runs smoothly with AVG Driver Updater. Automatically updates drivers to fix issues and improve performance.',
      price: '39.99',
      img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTV9GZeEQtJNuHlWoi9H_ojbq7liaRf9hTQkA&s',
    },
  ]);

  const handleNavigation = (value) => {
    navigate(`/avg/${value.name.toLowerCase().replace(/\s+/g, '-')}`);
    setProductToShow(value);
  };

  const handleCart = (item) => {
    if (!cart.some((bagItem) => bagItem.id === item.id)) {
      setCart((prevBag) => [...prevBag, item]);
      toast.success(`${item.name} Added to Cart`);
    } else {
      toast.warning(`${item.name} is already in the Cart`);
    }
  };

  return (
    <div className="antiv avg">
      <div className="antivMain">
        {/* antiv Banner */}
        <a href={`tel:${webinfo.phonecall}`}>
          <div className="antivBanner">
            <div className="antivBannerLeft">
              <div className="antivsupport">
                <h2>AVG Antivirus Support </h2>
                <div className="customersupportbox">
                  <h3>24 / 7 Customer Support ↓</h3>
                  <a href={`tel:${webinfo.phonecall}`}>{webinfo.phone}</a>
                </div>
              </div>
            </div>
            <div className="antivBannerRight">
              <div className="antivpic">
                <img
                  src="https://www.avg.com/content/dam/avg/imgs/avg-software-boxes/avg-ultimate.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </a>
        {/* end antiv Banner */}
        {/* antivlist */}
        <div className="antivlist">
          <div className="antivlisthead">
            <h2>AVG Antivirus ↓</h2>
          </div>
          <div className="antivlistmain">
            {product.map((product) => (
              <div className="antivlistitem" key={product.id}>
                <div
                  className="antivlistitemtop"
                  onClick={() => handleNavigation(product)}
                >
                  <img src={product.img} alt="" />
                </div>
                <div className="antivlistiteminfo">
                  <div className="antiviteminfohead">
                    <h3 onClick={() => handleNavigation(product)}>{product.name}</h3>
                    <p>$ {product.price}</p>
                  </div>
                  <div className="antiviteminfobtn">
                    {cart.some((prev) => prev.id === product.id) ? (
                      <button className="addbutton">Added To Bag ✔</button>
                    ) : (
                      <button className="addbutton" onClick={() => handleCart(product)}>
                        <img
                          src="https://img.icons8.com/ios-filled/50/FFFFFF/shopping-cart.png"
                          alt="shopping-cart"
                        />
                        <span>Add to Cart →</span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* end antivlist */}
      </div>
    </div>
  );
};

export default Avg;
