import React from 'react';
import './Categories.css'; 
import { useNavigate } from 'react-router-dom';
import { useProduct } from '../../context/ProductContext';


const Categories = () => {

  const {setSelectedAntiv} = useProduct();

  const categories = [
    { id: '01', name: "McAfee", img: "https://media.croma.com/image/upload/v1688213450/Croma%20Assets/Miscellanous/Software%20and%20Antivirus/Images/267210_ip13ww.png",route:'McAfee' },
    { id: '02', name: "Norton", img: "https://smartsoft.co.in/wp-content/uploads/2022/04/Norton-360-Standard-1-device-36-months.jpg",route:'Norton' },
    { id: '03', name: "Avast", img: "https://5.imimg.com/data5/CW/SR/IA/SELLER-64185364/antivirus-software.jpg",route:'Avast' },
    { id: '04', name: "Kaspersky", img: "https://computechstore.in/wp-content/uploads/2023/04/Kaspersky-Total-Security-1.jpg",route:'kaspersky' },
    { id: '05', name: "AVG", img: "https://crdms.images.consumerreports.org/f_auto,w_300/prod/products/cr/models/398521-antivirus-for-mac-avg-antivirus-for-mac-10004915.png",route:'avg' },
    { id: '06', name: "Bitdefender", img: "https://bitdefender.in/wp-content/uploads/2016/12/IS-Online-EN.png",route:'bitdefender' },
    { id: '07', name: "TotalAV", img: "https://www.totalav.com/product-box/antivirusPro",route:'totalav' },
  ];

  const navigate = useNavigate();
  const handleNavigation = (cate)=>{
    setSelectedAntiv(cate.name);
     navigate(`/${cate.route}`);
  }

  return (
    <div className="categories-container">
      {categories.map(category => (
        <div key={category.id} className="category-item" onClick={()=>handleNavigation(category)}>
          <img src={category.img} alt={category.name} className="category-img" />
          <h3 className="category-name">{category.name}</h3>
        </div>
      ))}
    </div>
  );
};

export default Categories;
