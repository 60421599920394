import React, { useState } from 'react';
import './Header.css';
import { useNavigate } from 'react-router-dom';
import HeaderMobile from './HeaderMobile';
import { useLocalContext } from '../../context/LocalContext';
import { useProduct } from '../../context/ProductContext';
import Offer from '../offer/Offer';
import logo from '../../assets/logo.png'

const Header = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { cart } = useProduct();
  const { webinfo } = useLocalContext();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const cartlength = cart.length;

  return (
    <>
    <Offer />
    <div className="nav">
      <div className="ham" onClick={() => setIsMenuOpen(true)}>
        <div className="ham__line"></div>
        <div className="ham__line"></div>
        <div className="ham__line"></div>
      </div>
      <div className="navleft">
        <div className="navlogo">
          <a href="/">
            {/* <h2>{webinfo.name}</h2> */}
            <img src={logo} alt="" />
          </a>
        </div>
      </div>
      {/* <div className="navmid">
        <input type="text" placeholder="Search For Items" />
        <button>
          <img src="https://img.icons8.com/ios/50/search--v1.png" alt="search" />
        </button>
      </div> */}
      <div className="navright">
        <div className="navlist">
          <ul className="notlogin">
            <li className="navitem" onClick={() => handleNavigation('/home')}>
              <a href className="navlink">
                Home
              </a>
            </li>
            <li className="navitem" onClick={() => handleNavigation('/activate-product-key')}>
              <a href className="navlink">
                Activate Product Key
              </a>
            </li>
            <li className="navitem" onClick={() => handleNavigation('/about')}>
              <a href className="navlink">
                About
              </a>
            </li>
            <li className="navitem" onClick={() => handleNavigation('/contact')}>
              <a href className="navlink">
                Contact
              </a>
            </li>
            <li className="navitem">
              <a href className="navlink" onClick={() => handleNavigation('/cart')}>
                <p>{cart.length > 0 ? cartlength : '0'}</p>
                <img src="https://img.icons8.com/ios/50/shopping-bag--v1.png" alt="Cart" />
              </a>
            </li>
            <li className="navitem" onClick={() => handleNavigation('/login')}>
              <a href className="navlink">
                Login/Register
              </a>
            </li>
          </ul>
        </div>
      </div>
      <HeaderMobile isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
    </div>
    </>
  );
};

export default Header;
