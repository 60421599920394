import React from 'react';
import './Footer.css'; 
import { useNavigate } from 'react-router-dom';
import { useLocalContext } from '../../context/LocalContext';
import { useProduct } from '../../context/ProductContext';

const Footer = () => {
  const { webinfo } = useLocalContext();
  const navigate = useNavigate();
  const {setSelectedAntiv} = useProduct();

  const handleNavigation = (path) => {
    navigate(path);
  };
  const categories = [
    { id: '01', name: "McAfee", img: "https://media.croma.com/image/upload/v1688213450/Croma%20Assets/Miscellanous/Software%20and%20Antivirus/Images/267210_ip13ww.png",route:'McAfee' },
    { id: '02', name: "Norton", img: "https://smartsoft.co.in/wp-content/uploads/2022/04/Norton-360-Standard-1-device-36-months.jpg",route:'Norton' },
    { id: '03', name: "Avast", img: "https://5.imimg.com/data5/CW/SR/IA/SELLER-64185364/antivirus-software.jpg",route:'Avast' },
    { id: '04', name: "Kaspersky", img: "https://computechstore.in/wp-content/uploads/2023/04/Kaspersky-Total-Security-1.jpg",route:'kaspersky' },
    { id: '05', name: "AVG", img: "https://crdms.images.consumerreports.org/f_auto,w_300/prod/products/cr/models/398521-antivirus-for-mac-avg-antivirus-for-mac-10004915.png",route:'avg' },
    { id: '06', name: "Bitdefender", img: "https://bitdefender.in/wp-content/uploads/2016/12/IS-Online-EN.png",route:'bitdefender' },
    { id: '07', name: "TotalAV", img: "https://www.totalav.com/product-box/antivirusPro",route:'totalav' },
  ];
  
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-brand">
          <h2>{webinfo.name}</h2>
          <p>
            "Savor the moment, cherish the flavors, and let every bite tell a story of delight and indulgence."
          </p>
        </div>
        <div className="footer-links">
          <div className="footer-column">
            <h3>{webinfo.name}</h3>
            <ul>
              <li onClick={() => handleNavigation('/about')}>About</li>
              <li onClick={() => handleNavigation('/contact')}>Contact</li>
            </ul>
          </div>
          <div className="footer-column">
            <h3>Categories</h3>
            <ul>
            {categories.map(category => (
             <li
                key={category.id}
                onClick={() => {
                handleNavigation(`/${category.route}`);
                setSelectedAntiv(category.name);
               }}
              >
              {category.name}
             </li>
             ))}

            </ul>
          </div>
          <div className="footer-column">
            <h3>Legal</h3>
            <ul>
              <li onClick={() => handleNavigation('/terms-&-conditions')}>Terms & Conditions</li>
              <li onClick={() => handleNavigation('/disclaimer')}>Disclaimer</li>
              <li onClick={() => handleNavigation('/privacy-policy')}>Privacy Policy</li>
              <li onClick={() => handleNavigation('/return-&-refund-policy')}>Return & Refund Policy</li>
              <li onClick={() => handleNavigation('/shipping-policy')}>Shipping Policy</li>
            </ul>
          </div>
          <div className="footer-column">
            <h3>Follow</h3>
            <ul>
              <li><a href target="_blank" rel="noopener noreferrer">Facebook</a></li>
              <li><a href target="_blank" rel="noopener noreferrer">Twitter</a></li>
              <li><a href target="_blank" rel="noopener noreferrer">Instagram</a></li>
              <li><a href target="_blank" rel="noopener noreferrer">YouTube</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© {new Date().getFullYear()} {webinfo.name} - All rights reserved</p>
        <div className="social-icons">
          <a href target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
          <a href target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
          </a>
          <a href target="_blank" rel="noopener noreferrer">
            <i className="fab fa-youtube"></i>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
