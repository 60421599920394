// Modal.js
import React from 'react';
import './Modal.css';

const Modal = ({ isOpen, onClickOverlay, children }) => {
  if (!isOpen) return null;

  const handleOverlayClick = () => {
    if (onClickOverlay) {
      onClickOverlay();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {/* <button className="modal-close" onClick={onClose}>&times;</button> */}
        {children}
      </div>
    </div>
  );
};

export default Modal;
