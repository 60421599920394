import React from 'react';
import './About.css';
import { useNavigate } from 'react-router-dom';
import about1 from '../../assets/img/about1.jpg';
import about2 from '../../assets/img/about2.png';

const About = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <section className="about-section">
      <div className="about-container">
        <h2 className="about-heading">About Us</h2>
        <p className="about-subtitle">
          Our company's mission is to provide top-notch antivirus solutions and computer peripherals, making your digital experience secure and seamless.
        </p>
        <div className="about-mission">
          <img src={about1} alt="Mission" className="about-image" />
          <div className="mission-text">
            <h3>Our Mission: Securing Your Digital World</h3>
            <p>
            We are dedicated to helping millions of customers protect their devices from the ever-evolving landscape of cyber threats. In today's digital age, where online activities are an integral part of our daily lives, safeguarding your personal information, sensitive data, and digital interactions is more critical than ever. Our goal is to make online security not only accessible but also affordable for everyone, regardless of their level of technical expertise or the type of device they use. <br /> <br />
            With a diverse range of antivirus solutions and security tools, we empower individuals and businesses alike to navigate the internet with confidence. We believe that robust cybersecurity shouldn't be a luxury reserved for the few; it should be a fundamental right available to all. That's why we are committed to continuously innovating and refining our products, ensuring they remain user-friendly while delivering top-tier protection against the latest threats, including viruses, malware, ransomware, and phishing attacks. <br /> <br />
           Our mission goes beyond just providing software; it’s about fostering a safer digital environment where our customers can work, learn, and play without fear. We strive to build a community of informed users who understand the importance of digital safety and have the tools they need to protect themselves and their loved ones. By prioritizing customer education, transparent communication, and exceptional support, we aim to be more than just a service provider—we aspire to be your trusted partner in navigating the digital world securely.
            </p>
          </div>
        </div>

        <div className="about-story">
          <div className="story-content">
          <h3>Our Story</h3>
            <p>
            Our company began as a small startup with a big vision: to revolutionize the way people protect their digital lives. What started as a team of tech enthusiasts with a shared passion for cybersecurity has grown into a leading e-commerce platform specializing in antivirus software and computer peripherals. Our journey is a testament to our unwavering commitment to innovation, customer satisfaction, and excellence in service. <br /><br />
            In the early days, we faced the typical challenges of a startup—limited resources, a competitive market, and the constant need to adapt to a rapidly changing technology landscape. However, it was our dedication to providing top-notch, reliable, and affordable security solutions that set us apart. As cyber threats evolved, so did we, continually expanding our product offerings to include not just antivirus software but also a comprehensive range of cybersecurity tools tailored to meet the needs of both individuals and businesses. <br /> <br />
            Our growth over the years has been fueled by a deep understanding of our customers' needs. We realized early on that protecting one's digital presence should be simple, accessible, and trustworthy. This philosophy guided us as we built our platform to offer an intuitive shopping experience, where users could find the right products with ease and confidence. Whether it's a small business looking to secure its network or a family needing protection across multiple devices, we provide solutions that cater to every requirement.
            </p>
          </div>
          <img src={about2} alt="Our Story" className="about-image" />
        </div>

        <div className="about-numbers">
          <h3>By the Numbers</h3>
          <div className="numbers-grid">
            <div className="numbers-item">
              <h4>5 Global Partners</h4>
              <p>Supporting customers worldwide.</p>
            </div>
            <div className="numbers-item">
              <h4>2500000+ Sales</h4>
              <p>Dedicated to providing exceptional service.</p>
            </div>
            <div className="numbers-item">
              <h4>100,000+ Customers</h4>
              <p>Trust us for their antivirus and peripheral needs.</p>
            </div>
          </div>
        </div>
        {/* <div className="about-cta">
          <button onClick={() => handleNavigation('/learn-more')} className="learn-more-btn">
            Learn More
          </button>
        </div> */}
      </div>
    </section>
  );
};

export default About;
