import React, { useState } from 'react';
import './TotalAV.css'; // Create this CSS for styling similar to Bitdefender.css
import '../Cate.css';
import { useProduct } from '../../../context/ProductContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLocalContext } from '../../../context/LocalContext';

const TotalAV = () => {
  const { cart, setCart, setProductToShow } = useProduct();
  const navigate = useNavigate();
  const { webinfo } = useLocalContext();

  const [product] = useState([
    {
      id: "302",
      name: "TotalAV Pro Antivirus",
      desc: "Advanced protection with added features like web protection, password vault, and identity protection. Ensures safe browsing and online shopping.",
      price: "39.99",
      img: "https://www.totalav.com/product-box/antivirusPro"
    },
    {
      id: "303",
      name: "TotalAV Internet Security",
      desc: "Full internet security suite with VPN, ad blocker, and malware protection. Protects against phishing, ransomware, and online fraud.",
      price: "59.99",
      img: "https://www.totalav.com/product-box/internetSecurity"
    },
    {
      id: "304",
      name: "TotalAV Total Security",
      desc: "Comprehensive security for all devices. Includes a VPN, identity protection, firewall, and secure browsing for Windows, Mac, Android, and iOS.",
      price: "79.99",
      img: "https://www.totalav.com/product-box/totalSecurity"
    }
  ]);

  const handleNavigation = (value) => {
    setProductToShow(value);
    navigate(`/totalav/${value.name.toLowerCase().replace(/\s+/g, '-')}`);
  };

  const handleCart = (item) => {
    if (!cart.some(bagItem => bagItem.id === item.id)) {
      setCart((prevBag) => [...prevBag, item]);
      toast.success(`${item.name} Added to Cart`);
    } else {
      toast.warning(`${item.name} is already in the Cart`);
    }
  };

  return (
    <div className='antiv totalav'>
      <div className="antivMain">
        {/* TotalAV Banner */}
        <a href={`tel:${webinfo.phonecall}`}>
          <div className="antivBanner">
            <div className="antivBannerLeft">
              <div className="antivsupport">
                <h2>TotalAV Antivirus Support </h2>
                <div className="customersupportbox">
                  <h3>24 / 7 Customer Support ↓</h3>
                  <a href={`tel:${webinfo.phonecall}`}>{webinfo.phone}</a>
                </div>
              </div>
            </div>
            <div className="antivBannerRight">
              <div className="antivpic">
                <img src='https://static.wixstatic.com/media/9cae24_f2cd9ba9bd3a42eb9bfd0291e734b76e~mv2.png/v1/fill/w_520,h_520,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/9cae24_f2cd9ba9bd3a42eb9bfd0291e734b76e~mv2.png' alt="TotalAV Support" />
              </div>
            </div>
          </div>
        </a>
        {/* end TotalAV Banner */}
        
        {/* TotalAV product list */}
        <div className="antivlist">
          <div className="antivlisthead">
            <h2>TotalAV Antivirus ↓</h2>
          </div>
          <div className="antivlistmain">
            {product.map((product) => (
              <div className="antivlistitem" key={product.id}>
                <div className="antivlistitemtop" onClick={() => handleNavigation(product)}>
                  <img src={product.img} alt={product.name} />
                </div>
                <div className="antivlistiteminfo">
                  <div className="antiviteminfohead">
                    <h3 onClick={() => handleNavigation(product)}>{product.name}</h3>
                    <p>$ {product.price}</p>
                  </div>
                  <div className="antiviteminfobtn">
                    {cart.some((prev) => prev.id === product.id) ? (
                      <button className="addbutton">Added To Bag ✔</button>
                    ) : (
                      <button className="addbutton" onClick={() => handleCart(product)}>
                        <img src="https://img.icons8.com/ios-filled/50/FFFFFF/shopping-cart.png" alt="shopping-cart" />
                        <span>Add to Cart →</span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* end TotalAV product list */}
      </div>
    </div>
  );
};

export default TotalAV;
