import React,{useEffect} from 'react';
import { HashRouter as Router, Route, Routes,useLocation } from 'react-router-dom';
import Home from '../components/home/Home';
import Header from '../components/Header/Header';
import NumberPinned from '../components/Pinned/NumberPinned';
import Footer from '../components/Footer/Footer';
import Chat from '../components/Chat/Chat';
import Cate from '../components/categories/Cate';
import ProductPage from '../components/productPage/ProductPage';
import Cart from '../components/Cart/Cart';
import Payment from '../components/Payment/Payment';
import Terms from '../components/T&C/Terms';
import About from '../components/About/About';
import Contact from '../components/Contact/Contact';
import Activate from '../components/Activate/Activate';
import Disclaimer from '../components/T&C/Disclaimer';
import Offer from '../components/offer/Offer';
import PrivacyPolicy from '../components/T&C/PrivacyPolicy';
import Refund from '../components/T&C/Refund';
import ShippingPolicy from '../components/T&C/ShippingPolicy';


const ScrollToTop = () => {
    const location = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0); 
    }, [location]);
  
    return null;
  };
  


const Container = () => {


    return (
       <Router>
        <Header />
        <NumberPinned />
        <Chat />
        <ScrollToTop />
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/activate-product-key" element={<Activate />}/>
            <Route exact path="/about" element={<About />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/disclaimer" element={<Disclaimer />} />
            <Route  path="/:antiv" element={<Cate />} />
            <Route path='/:antiv/:productname' element={<ProductPage />} />
            <Route  path="/cart" element={<Cart />} />
            <Route  path="/payment" element={<Payment />} />
            <Route  path="/terms-&-conditions" element={<Terms />} />
            <Route  path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route  path="/return-&-refund-policy" element={<Refund />} />
            <Route  path="/shipping-policy" element={<ShippingPolicy />} />
        </Routes>
        <Footer />
       </Router>
  )
}

export default Container