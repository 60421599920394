import React from 'react';
import './Terms.css';
import './Disclaimer.css';
import { useLocalContext } from '../../context/LocalContext';

const Disclaimer = () => {
  const { webinfo } = useLocalContext();

  return (
    <div className="disclaimer terms">
      <h1>Disclaimer</h1>
      <p>
        All registered trademarks, brand names, business names, and product names belong exclusively to their respective owners, {webinfo.name} wishes to make very clear. We want to make it clear that we do not pretend to be the owners of any company, brand, or product name. {webinfo.name} has only used third-party trademarks, brand names, or logos for illustration purposes. It’s important to remember that we never advise the use of any one product. We are only responsible for marketing and selling our goods and services. Being approved sellers of the goods displayed on our website makes us very happy. You can be sure that every product you buy from {webinfo.name} has the full support of the brands associated with it. We always strive to protect our customers by functioning according to the FTC’s Mail, Internet, or Telephone Order Merchandise Rule.
      </p>
    </div>
  );
};

export default Disclaimer;
