import React from 'react';
import './Review.css';

const reviews = [
    {
      id: 1,
      name: 'Jessica Smith',
      location: 'New York',
      comment: 'Great antivirus software! It was easy to purchase and the customer support team was incredibly helpful when I had questions.',
      image: 'https://randomuser.me/api/portraits/women/1.jpg',
      productImage: 'https://media.croma.com/image/upload/v1688213450/Croma%20Assets/Miscellanous/Software%20and%20Antivirus/Images/267210_ip13ww.png', 
      rating: 5,
    },
    {
      id: 2,
      name: 'Michael Johnson',
      location: 'California',
      comment: 'The installation was smooth, and the support team was prompt and helpful. The product works as advertised, and the product key delivery was fantastic!',
      image: 'https://randomuser.me/api/portraits/men/1.jpg',
      productImage: 'https://images-na.ssl-images-amazon.com/images/I/51-2E%2BfVLQL._AC_SL1000_.jpg', 
      rating: 4,
    },
    {
      id: 3,
      name: 'Emily Chen',
      location: 'Singapore',
      comment: 'I had a fantastic experience with this antivirus software. The online purchase process was seamless, and customer support was responsive and efficient.',
      image: 'https://randomuser.me/api/portraits/women/2.jpg',
      productImage: 'https://media.croma.com/image/upload/v1688213450/Croma%20Assets/Miscellanous/Software%20and%20Antivirus/Images/267210_ip13ww.png', 
      rating: 5,
    },
    {
      id: 4,
      name: 'David Lee',
      location: 'Toronto',
      comment: 'The software works as advertised, and the customer service team was available to help me through every step of the installation.',
      image: 'https://randomuser.me/api/portraits/men/3.jpg',
      productImage: 'https://images-cdn.ubuy.co.in/64f4eafe6a7a2309c27a576d-avast-antitrack-premium-1-year-3-pc.jpg', 
      rating: 4,
    },
    {
      id: 5,
      name: 'Sophia Brown',
      location: 'London',
      comment: 'I had some issues with activation, but the support team was quick to resolve it. Overall, a positive experience with the product and service.',
      image: 'https://randomuser.me/api/portraits/women/3.jpg',
      productImage: 'https://5.imimg.com/data5/ECOM/Default/2023/4/299131465/PL/TB/XD/187171725/avg-ult16n24en-avg-ultimate-2016-1188689-61cabb15-14a9-4b94-80a3-a47a6c14d6fa-500x500.jpg', 
      rating: 4,
    },
  ];
  
  
const StarRating = ({ rating }) => {
  const fullStars = Math.floor(rating);
  const halfStar = rating % 1 !== 0;
  const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

  return (
    <div className="star-rating">
      {Array(fullStars)
        .fill(0)
        .map((_, index) => (
          <span key={index} className="star full">&#9733;</span>
        ))}
      {halfStar && <span className="star half">&#9733;</span>}
      {Array(emptyStars)
        .fill(0)
        .map((_, index) => (
          <span key={index} className="star empty">&#9734;</span>
        ))}
    </div>
  );
};

const Review = () => {
  return (
    <div className="review-container">
      <h2>Our Customer Reviews and Testimonials</h2>
      <p>
        This slide shows the customer reviews and testimonials with details. It helps to establish credibility and reliability as a business.
      </p>
      <div className="review-grid">
        {reviews.map((review) => (
          <div key={review.id} className="review-card">
            <div className="review-image">
              <img src={review.image} alt={review.name} />
            </div>
            <h3 className="review-name">{review.name}</h3>
            <div className="review-location">{review.location}</div>
            <p className="review-comment">{review.comment}</p>
            <StarRating rating={review.rating} />
            <div className="product-image">
              <img src={review.productImage} alt="Product" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Review;
