import React from 'react';
// import './Home.css';
import Categories from '../categories/Categories';
import Products from '../product/Products';
import Services from '../Services/Services';
import Attached from '../Attached/Attached';
import Review from '../Review/Review';
import Banner from '../Banner/Banner';
import Slider from '../Slider/Slider'; 

const Home = () => {
  return (
    <>
      <div className='comp home' style={{ width: '100%' }}>
        <Slider /> 
      </div>
      <Categories />
      <Services />
      <Products />
      <Attached />
      <Review />
      <Banner />
    </>
  );
};

export default Home;
