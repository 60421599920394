import React, { useState } from 'react'
import './Bitdefender.css'
import '../Cate.css'
import { useProduct } from '../../../context/ProductContext'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
// import avast from '../../../assets/img/avast.png'
import { useLocalContext } from '../../../context/LocalContext'


const Bitdefender = () => {

    const {cart, setCart, setProductToShow} = useProduct();
    const navigate = useNavigate();
    const {webinfo} = useLocalContext();

    const [product] = useState([
        // Bitdefender Products
        {
          id: "201",
          name: "Bitdefender Antivirus Plus",
          desc: "Comprehensive protection for Windows against all types of malware. Includes a powerful firewall, advanced threat defense, and anti-phishing capabilities.",
          price: "29.99",
          img: "https://bitdefender.in/wp-content/uploads/2016/12/AV-Online-EN.png"
        },
        {
          id: "202",
          name: "Bitdefender Internet Security",
          desc: "Advanced security suite with privacy tools and parental controls. Protects against malware, ransomware, and online threats, and includes webcam protection and a VPN.",
          price: "49.99",
          img: "https://bitdefender.in/wp-content/uploads/2016/12/IS-Online-EN.png"
        },
        {
          id: "203",
          name: "Bitdefender Total Security",
          desc: "Complete protection for Windows, macOS, iOS, and Android devices. Includes real-time threat detection, privacy tools, and a password manager.",
          price: "79.99",
          img: "https://bitdefender.in/wp-content/uploads/2017/01/TS-Online-EN.png"
        },
        {
          id: "204",
          name: "Bitdefender Family Pack",
          desc: "Ultimate protection for the entire family. Covers up to 15 devices with multi-layered security and includes advanced parental controls.",
          price: "99.99",
          img: "https://bitdefender.in/wp-content/themes/bitdefender_india/images/product/FP-Online-EN.png"
        },
        {
          id: "205",
          name: "Bitdefender Premium Security",
          desc: "The most comprehensive package, with everything in Total Security plus a premium VPN and priority customer support.",
          price: "119.99",
          img: "https://www.bitdefender.com/content/dam/bitdefender/consumers/solutions/bitdefender-premium-security/PS-Online-EN(1).png"
        }
    ]);
    
      

      const handleNavigation = (value) => {
        setProductToShow(value);
        navigate(`/bitdefender/${value.name.toLowerCase().replace(/\s+/g, '-')}`)
    }

      
      const handleCart = (item) => {
        if (!cart.some(bagItem => bagItem.id === item.id)) {
          setCart((prevBag) => [...prevBag, item]);
          toast.success(`${item.name} Added to Cart`);
        } else {
          toast.warning(`${item.name} is already in the Cart`);
        }
      };

  return (
    <div className='antiv bitdefender'>
        <div className="antivMain">
        {/* antiv Banner */}
            <a href={`tel:${webinfo.phonecall}`}><div className="antivBanner">
                <div className="antivBannerLeft">
                  <div className="antivsupport">
                    <h2>Bitdefender Antivirus Support </h2>
                    <div className="customersupportbox">
                      <h3>24 / 7 Customer Support ↓</h3> 
                      <a href={`tel:${webinfo.phonecall}`}>{webinfo.phone}</a>
                    </div>
                  </div>
                </div>
                <div className="antivBannerRight">
                   <div className="antivpic">
                    <img src='https://bitdefender.in/wp-content/uploads/2016/12/IS-Online-EN.png' alt=""/>
                   </div>
                </div>
            </div>
           </a>
        {/* end antiv Banner */}
        {/* antivlist */}
       <div className="antivlist">
         <div className="antivlisthead">
            <h2>Bitdefender Antivirus ↓</h2>
         </div>
         <div className="antivlistmain">
            {product.map((product)=>(
                 <div className="antivlistitem">
                 <div className="antivlistitemtop" onClick={()=>handleNavigation(product)}>
                  <img src={product.img} alt="" />
                 </div>
                 <div className="antivlistiteminfo">
                   <div className="antiviteminfohead">
                     <h3 onClick={()=>handleNavigation(product)}>{product.name}</h3>
                     <p>$ {product.price}</p>
                   </div>
                   <div className="antiviteminfobtn">
                   {cart.some((prev)=> prev.id === product.id) ? ( <button className="addbutton" >Added To Bag ✔</button>):(
                   <button className="addbutton" onClick={() => handleCart(product)}>
                    <img src="https://img.icons8.com/ios-filled/50/FFFFFF/shopping-cart.png" alt="shopping-cart" />
                    <span>Add to Cart  →</span>
                   </button>
                    )}
                   </div>
                 </div>
             </div>
            ))} 
         </div>
       </div>
       {/* end antivlist */}

    </div>

        
    </div>
  )
}

export default Bitdefender