import React from 'react';
import './Banner.css';
import { useLocalContext } from '../../context/LocalContext';

const Banner = () => {

    const {webinfo} = useLocalContext();

  return (
  <a href={`tel:${webinfo.phonecall}`}><div className="banner">
      <div className="banner-photo">
        <img src="https://media.croma.com/image/upload/v1688213450/Croma%20Assets/Miscellanous/Software%20and%20Antivirus/Images/267210_ip13ww.png" alt="Antivirus 1" className="antivirus-image" />
        <img src="https://media.croma.com/image/upload/v1687940088/Croma%20Assets/Unclassified/Images/267107_rnxeoo.png" alt="Antivirus 2" className="antivirus-image" />
      </div>
      <div className="banner-content">
        <h1>Need Help with Your Purchase ?</h1>
        <p>Contact Support:</p>
        <a href={`tel:${webinfo.phonecall}`} className="phone-number">{webinfo.phone}</a>
      </div>
    </div>
    </a> 
  );
}

export default Banner;
