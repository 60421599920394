import React from 'react';
import './Contact.css';
import { useLocalContext } from '../../context/LocalContext';

const Contact = () => {
    const {webinfo} = useLocalContext();
  return (
    <div className="contact-us-container">
      <div className="contact-info">
        <h2>Contact Us</h2>
        <p>
          Not sure what you need? Our team is here to listen to your needs and provide the best
          solutions. Reach out to us anytime, and we'll be happy to assist.
        </p>
        <p>Email: {webinfo.email}</p>
        <p>Support: {webinfo.phone}</p>
      </div>

      <div className="contact-form">
        <h3>We’d love to hear from you! Let’s get in touch</h3>
        <form>
          <div className="form-row">
            <input type="text" placeholder="Full Name" required />
            <input type="text" placeholder="Company" />
          </div>
          <div className="form-row">
            <input type="email" placeholder="Email" required />
            <input type="tel" placeholder="Phone number" />
          </div>
          <input type="text" placeholder="Address" />
          <textarea placeholder="Your Message" rows="4"></textarea>
          <button type="submit" className="send-button">Send Message</button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
