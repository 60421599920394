import React, { useState, useEffect } from 'react';
import './Chat.css';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { app } from '../../firebase/firebaseConfig'; 
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'; 

const db = getFirestore(app);

const Chat = () => {
  const [showChat, setShowChat] = useState(true);
  const [response, setResponse] = useState('Hello, How Can I Help You?');
  const [currentOptions, setCurrentOptions] = useState([]);
  const [conversationStage, setConversationStage] = useState('initial');
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '', phone: '', issue: '' });
  const [formErrors, setFormErrors] = useState({ name: '', email: '', phone: '', issue: '' });
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [stageHistory, setStageHistory] = useState([]);

  const stages = {
    initial: {
      options: ['Need help!'],
      response: 'Hello! How can I help you today?',
      nextStage: 'handleIssue',
    },
    handleIssue: {
      options: ['Payment related problem', 'Interface not working', 'Any other'],
      response: 'Please select an option:',
      nextStage: 'showForm',
    },
    showForm: {
      options: [],
      response: 'Please Enter Your Issue Below:',
      nextStage: null,
    }
  };

  useEffect(() => {
    setCurrentOptions(stages['initial'].options);

    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const sendMessage = (message) => {
    const stage = stages[conversationStage];

    if (stage.nextStage === 'showForm') {
      setShowForm(true);
      setResponse('Please Enter Your Issue Below:');
      setCurrentOptions([]);
    } else {
      setStageHistory(prev => [...prev, conversationStage]); // Push current stage onto history
      const nextStage = stages[stage.nextStage];
      setConversationStage(stage.nextStage);
      setResponse(nextStage.response);
      setCurrentOptions(nextStage.options);
    }
  };

  const handleBack = () => {
    const previousStage = stageHistory.pop(); // Get the last stage from history
    if (previousStage) {
      setConversationStage(previousStage);
      const stage = stages[previousStage];
      setResponse(stage.response);
      setCurrentOptions(stage.options);
      setStageHistory(stageHistory);
      setShowForm(false); // Hide form when going back
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handlePhoneChange = (value) => {
    setFormData((prev) => ({ ...prev, phone: value }));
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = 'Name is required';
    if (!formData.issue) errors.issue = 'Issue description is required';
    if (!formData.phone) errors.phone = 'Phone number is required';
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        await addDoc(collection(db, 'user-info-chats'), formData);
        console.log('Form submitted and data added to Firestore:', formData);
        setFormData({ name: '', email: '', phone: '', issue: '' });
        setFormErrors({ name: '', email: '', phone: '', issue: '' });
        setShowForm(false);
        setConversationStage('initial');
        setStageHistory([]); // Clear stage history on successful submission
        setResponse('Thank you for your submission! How else can I assist you?');
        toast.success("Query Submitted Successfully!!");
        setCurrentOptions(stages['initial'].options);
      } catch (error) {
        console.error('Error adding document to Firestore:', error);
      }
    }
  };

  return (
    <div 
      className='chat' 
      style={
        showChat 
          ? { transform: "translateY(0)" } 
          : isMobileView 
            ? { transform: "translateX(24rem) translateY(38rem)", opacity: "0.5" } 
            : { transform: "translateY(34rem)" }
      }
    >
      <div className="close">
        {showChat && (
          <button onClick={() => {
            setConversationStage('initial');
            setShowChat(false);
          }}>X</button>
        )}
      </div>

      <div className="chattop" onClick={() => setShowChat(true)}>
        <div className="chatimg">
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAECklEQVR4nO2ZS4hcRRSGW2MSjE7EiGASFyouNOpClAhqFhoXBgUJYxbiQkQiJBMzjohOttm4UQgoghAIwYWIiAq6UlACxoSgE3XwhaDGJ2iUiSF66//1D2f6NLQ9tx+3qm5PCyko6NtddU5951H3VHWjkdAkLQ0h3EzyCQD7SL7fbSzJAwBeIDkJYKOk5Y3FbgDuALAfwAmSau/d5nSOAzAH4EUA95hBhg2wieSRzkXFgHRAfUtyq6SzawWQdDHJV3otJgWkrR+RdHUtECGEGwB8N+BCUkEE4CSAe3ND3FqWB3WCsAlDkg9mgSiK4tqqELlA6DAA7k6CkLQSwNdVlecEYRPmD0mXRYMA2BujODcIm/3NKIgQwnoA/44QiADcXhmE5DspSusAIXlY0lmVEjxRYV0gCiHcMjAIgOdGFQTAswNBWHkA4McRBvlF0pJBQNalKqsThM3w2tAXhOQDow5C8tGh5EfdIAD2D+KRAxmUzfaQP5tB/seDeOSzBEv9RXJ7r/OE/UZywsfG6oGky/uB/BQLAeA2X+xykjtJHvJy/KR9JrlD0jLXsxHA3wleebUfSKyltjnEWpJHe4ybsTEeZhO1lSwATkUIPWoh456YhwBwDMC4pDHrADYD+MLHf2Se8TD7NAHmE0nndAP5PkLgdrfwzhaEpFUl+bGqTf6Ez3kkxSt0OQtan7Ao7ZKu8rmHHWTcjXIXgB9s8XZh4WO2+LwPcryAARyXdFEZyGsRIOf6wv/057FO75qXfOEr/fmEP69I9Ijs3VcG8ngEyFgFkAs6QM7LAIKiKK77D4jfGFYFWedGOOSCNzvIJg8rS/w7/btxn3fQnouiuCYVhE2dL3cm5DIAv8bUPq3Etd2pR7J/1bFBTGYCOVa2cz1TUdCslda+/c64YAurLZYTfokx3gbxoW+/SwB8ngOE5LsLQOyWL0LQjrYX4jxMl24Qa3JW2gB+CyHc2G0bfqOisMLKjlZ4GpjlgW0Avgkc9DpsvkTxcUsB7LZkjQQ4BWCPpEtKIVzJlVVrIYOxPOl1gvO3+ZTlRusiIYRwE4AvK+raI2l1V4COXHkq0t1Wqk/ZJYak822L9QuNybYyxfrbki51wBUAnh/0CqpRpdkicpzf+1j2d5L3tXQWRXG9pAstj0g+6V5OA7FG8v46QdqAXjLDleifzgJicWzV6jBgypJWTc+kg7hVHh4SyJoSkLUl3vsnCsRzZW4IMNMlRtxVAvJzFIgLfH0IeWKJPW2ecU/s6pLs76WAVK6Ka+xTSX+/jQCA/CC1oCAduEm6YrEh2OxboyHaT3aL7I2nGznaIgLMkXwoC8SQQGYAfGNlkf8J+xbJx+x4nA1iGCBZF9urnQHhGY/U0/4PoXUaITIfviW3BVAAAAAASUVORK5CYII=" alt='' />
        </div>
        <div className="chatpara">
          <h2>Hello there..!!</h2>
          <p>I am Alex, your friendly assistant.</p>
        </div>
      </div>

      <div className="chatmain">
        <h2>{response}</h2>
        {showForm ? (
          <form onSubmit={handleSubmit} className="chat-form">
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleFormChange}
                aria-label="Name"
                className={`form-input ${formErrors.name ? 'input-error' : ''}`}
                placeholder="Enter your name"
              />
              {formErrors.name && <span className="error-message">{formErrors.name}</span>}
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleFormChange}
                aria-label="Email"
                className={`form-input ${formErrors.email ? 'input-error' : ''}`}
                placeholder="Enter your email"
              />
              {formErrors.email && <span className="error-message">{formErrors.email}</span>}
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone No.:</label>
              <PhoneInput
                international
                defaultCountry="US"
                value={formData.phone}
                onChange={handlePhoneChange}
                aria-label="Phone No."
                className={`form-input ${formErrors.phone ? 'input-error' : ''}`}
                placeholder="Enter your phone number"
              />
              {formErrors.phone && <span className="error-message">{formErrors.phone}</span>}
            </div>
            <div className="form-group">
              <label htmlFor="issue">Issue:</label>
              <textarea
                name="issue"
                value={formData.issue}
                onChange={handleFormChange}
                aria-label="Issue"
                className={`form-textarea ${formErrors.issue ? 'input-error' : ''}`}
                placeholder="Describe your issue"
              />
              {formErrors.issue && <span className="error-message">{formErrors.issue}</span>}
            </div>
            <button type="submit" className="submit-button">Submit</button>
            {stageHistory.length > 0 && (
              <button type="button" onClick={handleBack} className="back-button">Back</button>
            )}
          </form>
        ) : (
          <div className='chatoptions'>
            {currentOptions.map((option, index) => (
              <button
                key={index}
                onClick={() => sendMessage(option)}
                style={{ margin: '5px' }}
                aria-label={`Option ${option}`}
              >
                {option}
              </button>
            ))}
            {stageHistory.length > 0 && (
              <button type="button" onClick={handleBack} className="back-button">Back</button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Chat;
