import React from 'react';
import './Offer.css'; 

const Offer = () => {
  return (
    <div className="offer-banner">
      <p>🔥 Limited Time Offer: Get 50% Off on All Products! Use Code: SAVE50 🔥</p>
    </div>
  );
};

export default Offer;
