import React, { useState } from 'react'
import './Kaspersky.css'
import '../Cate.css'
import { useProduct } from '../../../context/ProductContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLocalContext } from '../../../context/LocalContext';

const Kaspersky = () => {

    const { cart, setCart, setProductToShow } = useProduct();
    const navigate = useNavigate();
    const { webinfo } = useLocalContext();

    const [product] = useState([
        {
          id: "001",
          name: "Kaspersky Anti-Virus",
          desc: "Reliable protection against viruses and malware. Enjoy real-time security and a smooth PC experience with Kaspersky's advanced antivirus technology.",
          price: "39.99",
          img: "https://rukminim2.flixcart.com/image/850/1000/kh3qkcw0/security-software/g/u/4/kaspersky-anti-virus-3-pc-for-3-years-original-imafx6xg7h8uayyh.jpeg?q=90&crop=false"
        },
        {
            id: "002",
            name: "Kaspersky Internet Security",
            desc: "Comprehensive protection for online activities. This package includes secure browsing, a firewall, and anti-spam features to keep your online experience safe.",
            price: "59.99",
            img: "https://computechstore.in/wp-content/uploads/2023/04/Kaspersky-Internet-Security-1.jpg"
        },
        {
          id: "003",
          name: "Kaspersky Total Security",
          desc: "Ultimate protection for your digital life. With Kaspersky Total Security, you get antivirus, internet security, and advanced privacy protection for your personal and financial data.",
          price: "79.99",
          img: "https://d2j6dbq0eux0bg.cloudfront.net/images/1107006/3286170020.jpg"
        },
        {
          id: "004",
          name: "Kaspersky Security Cloud",
          desc: "Adaptive security solution that adjusts to your needs. Includes all features of Kaspersky Total Security plus real-time protection against emerging threats.",
          price: "89.99",
          img: "https://ezzatec.com.br/wp-content/uploads/2021/11/security-cloud-personal.jpg"
        },
        {
          id: "005",
          name: "Kaspersky Small Office Security",
          desc: "Designed for small businesses, Kaspersky Small Office Security provides robust protection for your office PCs, file servers, and mobile devices.",
          price: "129.99",
          img: "https://images-cdn.ubuy.co.in/635fe3ef2c2f5a126c0c9e82-kaspersky-small-office-security-7.jpg"
        },
        {
            id: "006",
            name: "Kaspersky Safe Kids",
            desc: "Keep your children safe online with Kaspersky Safe Kids. Includes parental controls, web filtering, and activity monitoring to ensure a safe digital environment for your kids.",
            price: "49.99",
            img: "https://www.officedepot.com.gt/medias/36349.jpg-1200ftw?context=bWFzdGVyfHJvb3R8NDk0Nzk2fGltYWdlL2pwZWd8YUdWakwyZzJPUzh4TURjNU9UZzFOekV6T1RjME1pOHpOak0wT1M1cWNHZGZNVEl3TUdaMGR3fGMxYTljMTI2ZmEzMDllYWM2Y2I2ZjY0NDY2MzgwNmFmNTcxZjNlMzMzMGEzZDMxODhkNWVmNjJhMjg1MGIyZmY"
          },
          {
            id: "007",
            name: "Kaspersky VPN Secure Connection",
            desc: "Protect your online privacy and security with Kaspersky VPN. Encrypt your internet connection and browse the web safely from anywhere.",
            price: "59.99",
            img: "https://5.imimg.com/data5/SELLER/Default/2023/2/YX/HK/XW/162327682/kaspersky-secure-connection-vpn-500x500.jpg"
          },
    ]);

    const handleNavigation = (value) => {
        setProductToShow(value);
        navigate(`/kaspersky/${value.name.toLowerCase().replace(/\s+/g, '-')}`)
    }

    const handleCart = (item) => {
        if (!cart.some(bagItem => bagItem.id === item.id)) {
            setCart((prevBag) => [...prevBag, item]);
            toast.success(`${item.name} Added to Cart`);
        } else {
            toast.warning(`${item.name} is already in the Cart`);
        }
    };

    return (
        <div className='antiv kaspersky'>
            <div className="antivMain">
                {/* antiv Banner */}
                <a href={`tel:${webinfo.phonecall}`}>
                    <div className="antivBanner">
                        <div className="antivBannerLeft">
                            <div className="antivsupport">
                                <h2>Kaspersky Antivirus Support </h2>
                                <div className="customersupportbox">
                                    <h3>24 / 7 Customer Support ↓</h3> 
                                    <a href={`tel:${webinfo.phonecall}`}>{webinfo.phone}</a>
                                </div>
                            </div>
                        </div>
                        <div className="antivBannerRight">
                            <div className="antivpic">
                                <img src="https://image.goedkoopsteantivirus.com/img/501/540/catalog/Kaspersky/Antivirus_box.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </a>
                {/* end antiv Banner */}
                {/* antivlist */}
                <div className="antivlist">
                    <div className="antivlisthead">
                        <h2>Kaspersky Antivirus ↓</h2>
                    </div>
                    <div className="antivlistmain">
                        {product.map((product) => (
                            <div className="antivlistitem" key={product.id}>
                                <div className="antivlistitemtop" onClick={() => handleNavigation(product)}>
                                    <img src={product.img} alt="" />
                                </div>
                                <div className="antivlistiteminfo">
                                    <div className="antiviteminfohead">
                                        <h3 onClick={() => handleNavigation(product)}>{product.name}</h3>
                                        <p>$ {product.price}</p>
                                    </div>
                                    <div className="antiviteminfobtn">
                                        {cart.some((prev) => prev.id === product.id) ? (
                                            <button className="addbutton">Added To Bag ✔</button>
                                        ) : (
                                            <button className="addbutton" onClick={() => handleCart(product)}>
                                                <img src="https://img.icons8.com/ios-filled/50/FFFFFF/shopping-cart.png" alt="shopping-cart" />
                                                <span>Add to Cart  →</span>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* end antivlist */}
            </div>
        </div>
    )
}

export default Kaspersky
