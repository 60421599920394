import React, { useState } from 'react';
import { useProduct } from '../../context/ProductContext';
import './Cart.css';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from '../Breadcrumb/Breadcrumb'; 
import Banner from '../Banner/Banner';
import { toast } from 'react-toastify';

const Cart = () => {
  const { cart, setCart, selectedAntiv } = useProduct();
  const [couponCode, setCouponCode] = useState('');
  const [discount, setDiscount] = useState(0);
  const navigate = useNavigate();

  const validCoupons = {
    SAVE50: 50,  
  };

  const handleRemove = (itemId) => {
    setCart((prevCart) => prevCart.filter(item => item.id !== itemId));
  };

  const calculateOriginalTotal = () => {
    return cart.reduce((total, item) => total + parseFloat(item.price), 0).toFixed(2);
  };

  const calculateDiscountedTotal = () => {
    const originalTotal = parseFloat(calculateOriginalTotal());
    const discountAmount = (originalTotal * discount) / 100;
    return (originalTotal - discountAmount).toFixed(2);
  };

  const handleApplyCoupon = () => {
    if (validCoupons[couponCode]) {
      setDiscount(validCoupons[couponCode]);
      toast.success(`Coupon applied! You got ${validCoupons[couponCode]}% off.`);
    } else {
      toast.error("Invalid coupon code.");
    }
  };

  const handleProceedToPay = () => {
    if (cart.length > 0) {
      navigate('/payment');
    } else {
      toast.warning("Can't Proceed! Cart Is Empty");
    }
  };

  const breadcrumbPathsMap = {
    McAfee: [
      { name: 'Home', link: '/' },
      { name: 'McAfee', link: '/antivirus/McAfee_support' },
    ],
    Kaspersky: [
      { name: 'Home', link: '/' },
      { name: 'Kaspersky', link: '/antivirus/Kaspersky_support' },
    ],
    AVG: [
      { name: 'Home', link: '/' },
      { name: 'AVG', link: '/antivirus/avg_support' },
    ],
    Avast: [
      { name: 'Home', link: '/' },
      { name: 'Avast', link: '/antivirus/Avast_support' },
    ],
    Norton: [
      { name: 'Home', link: '/' },
      { name: 'Norton', link: '/antivirus/Norton_support' },
    ],
    Bitdefender: [
      { name: 'Home', link: '/' },
      { name: 'Bitdefender', link: '/antivirus/bitdefender_support' },
    ],
    TotalAV: [
      { name: 'Home', link: '/' },
      { name: 'TotalAV', link: '/antivirus/totalav_support' },
    ]
  };

  const breadcrumbPaths = breadcrumbPathsMap[selectedAntiv] || [{ name: 'Home', link: '/' }];

  return (
    <>
      <div className='cart'>
        <Breadcrumb paths={breadcrumbPaths} />
        <h1>Your Cart</h1>
        <div className='cart-container'>
          <div className='cart-content'>
            <ul className='cart-items'>
              {cart.length === 0 ? (
                <p>Your cart is empty</p>
              ) : (
                cart.map(item => (
                  <li key={item.id} className='cart-item'>
                    <img src={item.img} alt={item.name} className='cart-item-img' />
                    <div className='cart-item-details'>
                      <h2>{item.name}</h2>
                      <p>${item.price}</p>
                      <button onClick={() => handleRemove(item.id)}>Remove</button>
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>
          <div className='cart-total'>
            <h2>Cart Total :</h2>
            {/* Show Original Total if Discount is Applied */}
            {discount > 0 && (
              <p className="previous-total">
                <span className="cut-total">${calculateOriginalTotal()}</span>
              </p>
            )}
            <p>${calculateDiscountedTotal()}</p>

            {/* Apply Coupon Section */}
            <div className='coupon-section'>
              <input
                type='text'
                placeholder='Enter coupon code'
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
              />
              <button onClick={handleApplyCoupon}>Apply Coupon</button>
            </div>

            <button className='proceed-button' onClick={handleProceedToPay}>Proceed to Pay</button>

            <div className="footmidpartners">
              <h2>Our Payment Partners →</h2>
              <div className="footpartnerslist">
                <img src="https://img.icons8.com/color/50/visa.png" alt="visa" />
                <img src="https://img.icons8.com/color/50/mastercard.png" alt="mastercard" />
                <img src="https://img.icons8.com/color/50/discover.png" alt="discover" />
                <img src="https://img.icons8.com/fluency/48/jcb.png" alt="jcb" />
                <img src="https://img.icons8.com/color/50/paypal.png" alt="paypal" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Banner />
    </>
  );
};

export default Cart;
