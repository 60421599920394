import { createContext, useContext, useState } from "react";

const LocalContext = createContext();


export const LocalProvider = ({children})=>{
    const [webinfo,setwebinfo] = useState({
        name: "Virexcela.shop",
        phone: "+1 (888) 681-4978",
        phonecall: "+18886814978",
        email:"info@virexcela.com"
    });

    

    return(
        <LocalContext.Provider value={{webinfo,setwebinfo,}}>
            {children}
        </LocalContext.Provider>
    )
}


export const useLocalContext = () => useContext(LocalContext); 