import React from 'react'
import './Services.css'

const Services = () => {
  return (
    <div className='services'>
          <div className="hometopbot homerightbot">
                <div className="homeicon">
                    <div className="hicon">
                        <div className="himg">
                        <img  src="https://img.icons8.com/ios-filled/50/FFFFFF/delivery--v1.png" alt="delivery--v1"/>
                        </div>
                    </div>
                    <div className="htext">
                        <h3>Free Delivery</h3>
                    </div>
                </div>
                <div className="homeicon">
                    <div className="hicon">
                        <div className="himg">
                        <img  src="https://img.icons8.com/ios-filled/50/FFFFFF/private2.png" alt="private2"/>
                        </div>
                    </div>
                    <div className="htext">
                        <h3>Secure Payments</h3>
                    </div>
                </div>
                <div className="homeicon">
                    <div className="hicon">
                        <div className="himg">
                        <img src="https://img.icons8.com/ios-filled/50/FFFFFF/key.png" alt="key"/>
                        </div>
                    </div>
                    <div className="htext">
                        <h3>Product Key Activation</h3>
                    </div>
                </div>
                <div className="homeicon">
                    <div className="hicon">
                        <div className="himg">
                        <img  src="https://img.icons8.com/ios-filled/50/FFFFFF/customer-support.png" alt="customer-support"/>
                        </div>
                    </div>
                    <div className="htext">
                        <h3>24 / 7 Customer Support</h3>
                    </div>
                </div>
              

            </div>
    </div>
  )
}

export default Services