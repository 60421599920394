import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import avast from '../../assets/avast.png';
import { useLocalContext } from '../../context/LocalContext';
import './Slider.css'; 

const Slider = () => {
  const { webinfo } = useLocalContext();
  const phonecall = webinfo.phonecall;
  const phone = webinfo.phone;

  const slides = [
    {
      id: 1,
      title: 'McAfee Antivirus Support',
      img: 'https://media.croma.com/image/upload/v1688213450/Croma%20Assets/Miscellanous/Software%20and%20Antivirus/Images/267210_ip13ww.png',
    },
    {
      id: 2,
      title: 'Norton Antivirus Support',
      img: 'https://media.croma.com/image/upload/v1687940088/Croma%20Assets/Unclassified/Images/267107_rnxeoo.png',
    },
    {
      id: 3,
      title: 'Avast Antivirus Support',
      img: avast,
    },
    {
      id: 4,
      title: 'Kaspersky Antivirus Support',
      img: 'https://image.goedkoopsteantivirus.com/img/501/540/catalog/Kaspersky/Antivirus_box.png',
    },
    {
      id: 5,
      title: 'AVG Antivirus Support',
      img: 'https://crdms.images.consumerreports.org/prod/products/cr/models/398521-antivirus-for-mac-avg-antivirus-for-mac-10004915.png',
    },
    {
      id: 6,
      title: 'Bitdefender Antivirus Support',
      img: 'https://bitdefender.in/wp-content/uploads/2016/12/IS-Online-EN.png',
    },
    {
      id: 7,
      title: 'TotalAV Antivirus Support',
      img: 'https://static.wixstatic.com/media/9cae24_f2cd9ba9bd3a42eb9bfd0291e734b76e~mv2.png/v1/fill/w_520,h_520,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/9cae24_f2cd9ba9bd3a42eb9bfd0291e734b76e~mv2.png',
    },
  ];

  return (
    <div className='slider'>
      <Carousel
        infiniteLoop
        autoPlay
        showStatus={false}
        showThumbs={false}
        emulateTouch
      >
        {slides.map((slide) => (
          <div key={slide.id} className={`slide${slide.id} slide`}>
            <a href={`tel:${phonecall}`}>
              <div className="antivBanner">
                <div className="antivBannerLeft">
                  <div className="antivsupport">
                    <h2>{slide.title} </h2>
                    <div className="customersupportbox">
                      <h3>24 / 7 Customer Support ↓</h3>
                      <a href={`tel:${phonecall}`}>{phone}</a>
                    </div>
                  </div>
                </div>
                <div className="antivBannerRight">
                  <div className="antivpic">
                    <img src={slide.img} alt={slide.title} />
                  </div>
                </div>
              </div>
            </a>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Slider;
