import React, { useEffect, useRef } from 'react';
import './Attached.css';

const Attached = () => {
  const imagesRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('in-view');
          } else {
            entry.target.classList.remove('in-view');
          }
        });
      },
      { threshold: 0.1 } 
    );

 
    imagesRef.current.forEach((img) => {
      if (img) observer.observe(img);
    });

    return () => {
      imagesRef.current.forEach((img) => {
        if (img) observer.unobserve(img);
      });
    };
  }, []);

  return (
    <div className="parallax">
      <div className="attached-content">
        <h1>Secure Your Device Now</h1>
        <p>Protect your device with reliable antivirus software. It detects, blocks, and removes threats, keeping your data safe and your device running smoothly.</p>
        <button>Buy Now</button>
      </div>
      <div className="attached-pics">
        <div className="imggroup1">
          <img
            className="imgg1"
            src="https://media.croma.com/image/upload/v1688213450/Croma%20Assets/Miscellanous/Software%20and%20Antivirus/Images/267210_ip13ww.png"
            alt=""
            ref={(el) => (imagesRef.current[0] = el)}
          />
          <img
            className="imgg2"
            src="https://media.croma.com/image/upload/v1687940088/Croma%20Assets/Unclassified/Images/267107_rnxeoo.png"
            alt=""
            ref={(el) => (imagesRef.current[1] = el)}
          />
        </div>
        <div className="imggroup2">
          <img
            className="imgg3"
            src="https://image.goedkoopsteantivirus.com/img/501/540/catalog/Kaspersky/Antivirus_box.png"
            alt=""
            ref={(el) => (imagesRef.current[2] = el)}
          />
          <img
            className="imgg4"
            src="https://crdms.images.consumerreports.org/prod/products/cr/models/398521-antivirus-for-mac-avg-antivirus-for-mac-10004915.png"
            alt=""
            ref={(el) => (imagesRef.current[3] = el)}
          />
        </div>
      </div>
    </div>
  );
};

export default Attached;
