import React from 'react'
import Avast from './Avast/Avast';
import McAfee from './McAfee/McAfee';
import Norton from './Norton/Norton';
import { useProduct } from '../../context/ProductContext';
// import { toast } from 'react-toastify';
import Kaspersky from './Kaspersky/Kaspersky';
import Avg from './Avg/Avg';
import Banner from '../Banner/Banner.jsx';
import Bitdefender from './Bitdefender/Bitdefender.jsx';
import Services from '../Services/Services.jsx';
import TotalAV from './TotalAV/TotalAV.jsx';


const Cate = () => {

  const {selectedAntiv} = useProduct();

  const renderComponent = () => {
    switch (selectedAntiv) {
      case 'Avast':
        return <Avast />;
      case 'McAfee':
        return <McAfee />;
      case 'Norton':
        return <Norton />;
      case 'Kaspersky':
       return <Kaspersky />;
       case 'AVG':
        return <Avg />;
        case 'Bitdefender':
        return <Bitdefender />;
        case 'TotalAV':
          return <TotalAV />;
      default:
        return <p className='mid'>Currently Not Available, We are Adding Soon!!🙂</p>;
    }
  };

  return (
    <div className="opencategory">
      {renderComponent()}
      <Services />
      <Banner />
    </div>
  );
}

export default Cate